/* ++++++++++++++++++++++++++++++++++++++++++++
//
// メニューコントロール
//
++++++++++++++++++++++++++++++++++++++++++++ */

(function($){
  $(function(){
    /* メニューコントロール
    -------------------------------------------- */
    // gns.menuFlag は gns.menuFlag
    let bodyWidth = gns.win.innerWidth();
    let wrapper = $('#wrapper');
    let header = $("#site_header");
    let spMenuIcon = $('#sp_menu_icon');
    let globalNav = $('#global_nav');
    let spMenuCloseBtn = $('#sp_menu_close_icon, #sp_menu_close_btn');
    let container = $('#container');
    let contents = $('#contents');
    let scrollPos;

    gns.mainSlider = $('#main_slideshow');

    $([spMenuIcon[0], spMenuCloseBtn[0], spMenuCloseBtn[1]]).on('click', function(){
      bodyWidth = gns.win.innerWidth();
      if(!gns.menuFlag) {
        spMenuControl('on');
      } else {
        spMenuControl('off');
      }
    });
    /*
    $(globalNav.find('nav a')).on('click', function(){
      bodyWidth = gns.win.innerWidth();
      spMenuControl('off');
    });
    globalNav.hammer().on('swiperight', function(){// スワイプでクローズ
      if(gns.menuFlag) spMenuControl('off');
    });
    */

    /* スマホ用メニュー表示非表示
    -------------------------------------------- */
    function spMenuControl(modeType, eventType) {
      let mode = modeType || "on";
      if (mode == "on") {
        // それまでにスクロールされた位置を記憶
        scrollPos = gns.win.scrollTop();
        // スライダー
        if (gns.mainSlider != null && gns.mainSlider.length > 0) {
          gns.mainSlider.slick("slickPause");
        }
        // メニュー表示
        globalNav.velocity("slideDown", { duration: 200 });
        // コンテンツのクリック無効
        contents.find("a").on("click", cancelEvent);
        // コンテンツのポジション
        let posValue = "fixed";
        wrapper.css({
          width: gns.win.innerWidth(),
          position: posValue,
          top: -scrollPos
        });
        // その他
        gns.menuFlag = true;
        // ピンチズームクラス削除
        gns.html.removeClass("pinch_zoom");
        // htmlにクラス追加
        gns.html.addClass("sp_menu_on");
      } else if (mode == "off") {
        // メニュー非表示
        // globalNav.scrollTop(0);
        if (eventType != "orientationEvent") globalNav.velocity("slideUp", {
            duration: 200
          });
        // コンテンツのクリック有効
        contents.find("a").off("click", cancelEvent);
        // その他
        gns.menuFlag = false;
        // styleを削除
        wrapper.removeAttr("style");
        // htmlにクラス削除
        gns.html.removeClass("sp_menu_on");
        // スライダー再生
        if (gns.mainSlider != null && gns.mainSlider.length > 0) {
          gns.mainSlider.slick("slickPlay");
        }
        // スクロール位置を戻す
        gns.win.scrollTop(scrollPos);
      }
    }

    /* ピンチズーム用
    -------------------------------------------- */
    gns.win.on('load touchend', function(){
      let zoom = getZoomePacentage();
      if(zoom > 1) {
        gns.html.addClass('pinch_zoom');
      } else {
        gns.html.removeClass('pinch_zoom');
      }
    });

    /* 回転時A （スマホのみ） AかBかどっちかのみを使う
    --------------------------------------------
    gns.win.on(gns.orientationEvent, function(){
      if(gns.menuFlag){
        wrapper.css({
          'width':gns.win.innerWidth()
        });
      }
      spMenuControl('off');
    });
    */

    // 回転時B （スマホ・タブレット・PCまで対応したい時）
    /*
    gns.win.on(gns.orientationEvent, function(){
      if(gns.menuFlag){
        if(gns.winSize == ('m-l_size')) {
          hideSpMenu();
        } else {
          wrapper.css({
            'width':gns.win.innerWidth()
          });
        }
      } else {
        if(gns.winSize == ('m-l_size')) {
          globalNav.removeAttr('style');
        }
      }
    });
    */

    // リサイズ時（PCのみ反応）
    if(!gns.deviceType.tab && !gns.deviceType.sp){
      let resizeTimer = false;
      gns.win.on('resize', function(){
        if(resizeTimer !== false) clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function(){
          if(gns.winSize == ('m-l_size')) {
            hideSpMenu();
          }
        }, 200);
      });
    }

    function hideSpMenu(){
      // コンテンツのクリック有効
      contents.find('a').off('click', cancelEvent);
      // メニューフラッグ
      gns.menuFlag = false;
      // styleを削除
      wrapper.removeAttr('style');
      globalNav.removeAttr('style');
      // htmlにクラス削除
      gns.html.removeClass('sp_menu_on');
      // スライダー再生
      if(gns.mainSlider != null && gns.mainSlider.length > 0)  gns.mainSlider.slick('slickPlay');
    }

    /* スクロール時のヘッダーのコントロール
    -------------------------------------------- */
    let headerH = header.height();
    let tempScVal;
    gns.win.scroll(function() {
      let scVal = $(this).scrollTop();
      if (scVal > headerH) {
        if(tempScVal != undefined) {
          if(tempScVal > scVal && ((tempScVal-scVal) > 10)) {// 上にスワイプ市立木
            header.removeClass('hidden');
            header.addClass('fixed');
          } else if(tempScVal < scVal && ((scVal-tempScVal) > 10)) {
            if(header.hasClass('fixed')) {
              header.addClass('hidden');
              header.removeClass('fixed');
            }
          }
        }
        tempScVal = scVal;
      } else {
        header.removeClass('fixed');
        header.removeClass('hidden');
      }
    });
    /*
    let headerH = header.height();
    gns.win.scroll(function() {
      let scVal = $(this).scrollTop();
      if (scVal > headerH) {
        header.addClass('fixed');
      } else {
        header.removeClass('fixed');
      }
    });
    */

    /* 同ページアンカーリンクの時
    --------------------------------------------
    let cUrl = document.URL;
    let tmpUrl;
    globalNav.find('#gn-wrap_01 a').on('click', function(){
      if(gns.menuFlag) {
        if(document.domain != 'localhost') {
          tmpUrl = $(this).attr('href').replace(cUrl, '');
        } else {
          tmpUrl = $(this).attr('href');
        }
        if(tmpUrl.slice(0,2).match(/#/)) {
          spMenuControl('off');
        }
      }
    });
    */

    /* フォントサイズの拡大・縮小コントロール
    --------------------------------------------
    let fontSizeBtn = $('#cfs_btn');
    let changeFS = changeFontSize();

    fontSizeBtn.on('click', function() {
      changeFS();
    });
    function changeFontSize() {
      this.changed = false;
      return function(reset) {
        reset = reset || false;
        if (!changed && !reset) {
          gns.html.css({
            'font-size': '75%'
          });
          gns.html.addClass('fs_changed');
          this.changed = true;
        } else if (changed || reset) {
          gns.html.css({
            'font-size': '62.5%'
          });
          gns.html.removeClass('fs_changed');
          this.changed = false;
        }
      }
    };
    */

  })
})(jQuery);